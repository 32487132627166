<template>
  <el-container class="index">
    <el-header height="80px">
      <CommonHeader></CommonHeader>
    </el-header>

    <el-container class="indexWarp">
      <div
        class="indexSideMenu"
        v-bind:style="{ minHeight: menuHeight }"
        :class="{ w64: collapse, w22: !collapse }"
      >
        <SideMenu :itemArr="NavArr" :tab-arr="tabArr"></SideMenu>
      </div>
      <el-container class="indexMain" v-bind:style="{ minHeight: menuHeight }">
       <div
            class="indexMainPage"
            v-bind:style="{ minHeight: indexMainPageHeight }"
          >
            <router-view @update-tabList="updateTabList" />
          </div>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import "@/scss/index.scss";
import CommonHeader from "@/components/CommonHeader.vue";
import SideMenu from "@/components/SideMenu.vue";
import API from "@/api/api_login";
import bus from "@/vuex/bus";
export default {
  name: "index",
  components: {
    CommonHeader,
    SideMenu
  },
  data() {
    return {
      menuHeight: null,
      indexMainPageHeight: null,
      collapse: false,
      NavArr: [],
      tabArr: []
    };
  },
  methods: {
    handleResize() {
      let $body = document.getElementsByTagName("body")[0];
      this.menuHeight = window.innerHeight - 80 + "px";
      this.indexMainPageHeight = window.innerHeight - 80 - 90 + "px";
    },

    /**
     * 获取当前用户的菜单
     */
    getUserMenus() {
      API.getUserMenus().then(res => {
        if (res.status == 200 && res.data.code == 0) {
          const list = res.data.data.currentUserMenus;
          list.sort((a, b) => {
            return a.id - b.id;
          });
          for (let i = 0; i < list.length; i++) {
            let ele = list[i];
            ele.index = i + 1 + "";
            if (ele.children.length > 0) {
              ele.children.sort((a, b) => {
                return a.id - b.id;
              });
              for (let j = 0; j < ele.children.length; j++) {
                ele.children[j].index = i + 1 + "-" + (j + 1);
              }
            }
          }
          this.NavArr = list;
        }
      });
    },
    updateTabList(data) {
      this.tabArr = data;
    }
  },
  beforeMount() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
  },
  created() {
    // this.getUserMenus();
    bus.$on("collapse", msg => {
      this.collapse = msg;
    });
  }
};
</script>

<style scoped></style>
