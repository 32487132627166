<template>
  <el-menu
    :unique-opened="true"
    :default-active="defaultIndex"
    class="el-menu-vertical-demo"
    :collapse="collapse"
    :default-openeds="['1']"
    ref="menuBars"
  >
    <template v-for="(item, index) in navList">
      <template v-if="item.children.length == 0">
        <el-menu-item
          :index="item.menuIndex" 
          :key="`noChild${index}`"
          @click="onClickNav(item)"
        >
          <div :class="`icon icon${index + 1}`"></div>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </template>
      <template v-else>
        <el-submenu :index="`${item.menuIndex}`" :key="`hasChild${index}`">
          <template slot="title">
            <div :class="`icon icon${index + 1}`"></div>
            <span slot="title">{{ item.name }}</span>
          </template>
          <el-menu-item
            v-for="(subItem, subItemIndex) in item.children"
            :key="`subItem${subItemIndex}`"
            :index="`${item.menuIndex}-${(subItemIndex + 1)}`"
            @click="onClickNav(subItem)"
            class="little color-default"
          >
            <div class="iconFrame"></div>
            <span>{{ subItem.name }}</span>
          </el-menu-item>
        </el-submenu>
      </template>
    </template>
  </el-menu>
</template>

<script>
import { navList, defMenu } from "@/util/constant";
import API from "@/api/api_login";
import { mapGetters } from "vuex";

export default {
  name: "sidemenu",
  data() {
    return {
      navList, //导航列表
      collapse: false,
      defaultIndex: "1-1", //默认选中的Nav
      routerName: '', //
      tabList: [
        defMenu//通过点击nav打开的tab
      ]
    };
  },
  watch: {
    $route: {
      handler(val) {
        const { meta:{tabName} } = val;
        // this.defaultIndex = tabName; 
        this.defaultIndex = '1-1';
        const tabList = this.getTablistFromLocalStorage();
        //第一次进入页面设置tab
        if (!tabList.length > 0) {
          this.setTablistToLocalStorage();
        }
      },
      deep: true, // 深度监听
      immediate: true // 第一次初始化渲染就可以监听到
    },
    tabArr(newVal) {
      this.tabList = newVal;
      if(newVal.length > 0) {
        this.setMenuIndex(newVal);
      }
    }
  },
  props: {
    tabArr: {
      default: () => {
        return defMenu;
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCurTabName'
    ])
  },
  mounted() {},
  methods: {
    //获取tablist
    getTablistFromLocalStorage() {
      return JSON.parse(localStorage.getItem("tabList") || "[]");
    },
    //设置tablist
    setTablistToLocalStorage() {
      localStorage.setItem("tabList", JSON.stringify(this.tabList));
    },

    //点击导航触发事件
    onClickNav(tabItem) {
      const { routerName, name, menuIndex } = tabItem;
      this.tabList = this.getTablistFromLocalStorage();
      //维护打开的tab数组
      if (
        JSON.stringify(this.tabList).indexOf(
          JSON.stringify({ name, routerName, menuIndex })
        ) === -1
      ) {
        this.tabList.push({ name, routerName, menuIndex });
      }
      // //将tablist本地存储，在mainlayout使用
      this.setTablistToLocalStorage();
      this.$router.push({ name: routerName });
    },
    setMenuIndex(newVal) {
      const curActiveIndex = this.$refs.menuBars.activeIndex;
      let setActiveIndex = curActiveIndex;
      newVal.forEach(item => {
        if(item.routerName === this.getCurTabName) {
          setActiveIndex = item.menuIndex;
        }
      });
      this.$refs.menuBars.activeIndex = setActiveIndex;
      this.$refs.menuBars.open(setActiveIndex.split("-")[0]);
    }
  },
  created() {
  }
};
</script>
<style scoped></style>
