<template>
  <nav class="operateManger">
    <div class="top-logo">
      <img :src="imgLogo" class="logo-icon" />
      <p class="logo-word">零数数字资产服务平台管理后台</p>
    </div>

    <div class="menuBox">
      <div class="menuNameBox">
        <div class="menuName">
          <p class="user-name">{{ userInfo.username }}</p>
          <img :src="CompanyHead" class="menuHead" />
        </div>
      </div>
      <div class="menuList">
        <div class="menu-item">
          <span class="ls-icons icon-person active"></span>
          <span class="content-wrap">
            <span class="content">{{ userInfo.account }}</span>
            <span
              @click="copyConent(userInfo.account)"
              class="btn font-underline check"
              >复制</span
            >
          </span>
        </div>
        <div class="menu-item">
          <span class="ls-icons icon-safety"></span>
          <span class="content-wrap un-active">
            <span class="content">安全认证</span>
            <span class="btn font-underline">去认证</span>
          </span>
        </div>
        <div class="menu-item">
          <span class="ls-icons icon-key active"></span>
          <span class="content-wrap">
            <span class="content">密钥状态：{{ keyStatus }}</span>
          </span>
        </div>
        <div class="menu-item">
          <span class="ls-icons icon-pwd"></span>
          <span class="content-wrap un-active">
            <span class="content">修改密码</span>
          </span>
        </div>
        <div class="menu-item">
          <span class="ls-icons icon-exit active"></span>
          <span class="content-wrap">
            <span @click="logout" class="content font-underline">退出登录</span>
          </span>
        </div>
        <!-- <div class="menuItem" @click="changePassword">修改密码</div>
        <div class="menuItem exit" @click="logout">退出</div> -->
      </div>
    </div>
  </nav>
</template>

<script>
import bus from "@/vuex/bus";
import { mapGetters } from "vuex";
import API from "@/api/api_login";

export default {
  name: "index_top",
  data() {
    return {
      imgLogo: require("@/assets/common/logo.png"),
      CompanyHead: require("@/assets/common/default_head.png"),
      // sysUserName: null,
      activeIndex: "1",
      collapse: false,
    };
  },
  mounted() {
    // let sysUserName = sessionStorage.getItem("userInfo").split("_")[4];
    // if (sysUserName) {
    //   this.sysUserName = sysUserName;
    // }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    keyStatus() {
      return this.userInfo.escrowAccount ? "托管" : "";
    },
  },
  methods: {
    changePassword() {
      this.$router.push({
        name: "ChangePassword",
      });
    },
    logout() {
      this.$confirm("确认退出吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          API.loginOut()
            .then(() => {
              localStorage.clear();
              sessionStorage.removeItem("userInfo");
              this.$router.push("/login");
            })
            .catch((err) => {
              this.$message.error({
                message: data.msg || this.$CommonMsg["networkErr"],
              });
            });
        })
        .catch(() => {});
    },
    handleSelect(key, keyPath) {
      //  console.log(key, keyPath);
    },
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
    copyConent(text) {
      this.$copyText(text).then(
        (e) => {
          this.$message({
            message: "复制成功~",
            type: "success",
          });
        },
        (e) => {}
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.operateManger {
  .menuNameBox {
    .user-name {
      position: relative;
      min-height: 32px;
      &::after {
        content: "";
        display: none;
        position: absolute;
        bottom: -14px;
        left: 50%;
        transform: translateX(-50%);
        border: 8px solid #ffffff;
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0);
      }
    }
  }

  .menuList {
    width: auto !important;
    .font-underline {
      text-decoration: underline;
    }
    .menu-item {
      position: relative;
      font-size: 0;
      white-space: nowrap;
      cursor: pointer;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .ls-icons {
        vertical-align: top;
        margin-top: 17px;
      }
      .content-wrap {
        display: inline-block;
        width: 200px;
        border-bottom: 1px solid #e3e5e8;
        margin-left: 12px;
        padding: 16px 0;
        display: inline-flex;
        justify-content: space-between;
        vertical-align: top;
        &.un-active {
          color: #8f96a3;
          opacity: 0.6;
        }
      }
      .content {
        width: 148px;
        word-break: break-all;
        white-space: pre-wrap;
        text-align: left;
      }

      &:first-child {
        .ls-icons {
          margin-top: 1px;
        }
        .content-wrap {
          padding-top: 0;
        }
      }
      &:last-child {
        .content-wrap {
          padding-bottom: 0;
          border: 0;
        }
      }
    }
  }
}
</style>